import { chakra, SimpleGrid, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { DatoAsset, DatoAssetData } from "../../components/Dato/DatoAsset";
import { Hx } from "../../components/Heading/Heading";

type LogoWallSectionProps = {
	title: string;
	logos: Array<DatoAssetData>;
};

export const LogoWallSection: React.FC<LogoWallSectionProps> = ({
	title,
	logos,
}) => {
	const styles = useMultiStyleConfig("LogoWallSection");

	return (
		<chakra.div __css={styles.wrapper}>
			<Hx size="h2" sx={styles.title}>
				{title}
			</Hx>
			<SimpleGrid
				columns={{ base: 2, md: 4 }}
				columnGap={{ base: 8, md: 16, lg: 32 }}
				rowGap={12}
				sx={styles.logoGrid}
			>
				{logos.map((logo) => {
					return (
						<chakra.div key={logo.url} __css={styles.logoWrapper}>
							<DatoAsset
								sx={styles.logo}
								data={logo}
								datoImageProps={{
									pictureStyle: { objectFit: "cover" },
								}}
							/>
						</chakra.div>
					);
				})}
			</SimpleGrid>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
