import { ChakraProps, Input, useMultiStyleConfig } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormErrorMessageI18n } from "src/components/FormErrorMessageI18n/FormErrorMessageI18n";
import { Hx } from "src/components/Heading/Heading";
import { Textarea } from "src/components/Textarea/Textarea";
import { FormLabel, FormControl } from "src/components/forms";
import { useTranslatedString } from "src/i18n/i18n";
import { Email } from "src/icons";
import { z } from "src/lib/zod";
import { TypeOf } from "zod";
import {
	commonEmailPropsSchema,
	ContactForm,
	ContactFormTitle,
	ContactFormProductSelect,
} from "./ContactForm";

const emailSchema = commonEmailPropsSchema.extend({
	name: z.string().min(1),
	email: z.string().email().min(1),
	message: z.string().min(1).max(500),
});

export const EmailForm: React.FC<ChakraProps> = (props) => {
	const styles = useMultiStyleConfig("ContactForm");
	const t = useTranslatedString();

	const form = useForm<TypeOf<typeof emailSchema>>({
		resolver: zodResolver(emailSchema),
		mode: "onTouched",
		defaultValues: {
			emailSubject: t("contact.subject.email"),
		},
	});

	const {
		register,
		control,
		formState: { errors, isValid },
	} = form;

	return (
		<ContactForm
			type="message"
			isValid={isValid}
			buttonText={t("contact.send")}
			form={form}
			{...props}
		>
			<ContactFormTitle>
				<Email fillRule="evenodd" />
				<Hx size="h3">{t("contact.email")}</Hx>
			</ContactFormTitle>
			<ContactFormProductSelect />
			<FormControl
				isInvalid={Boolean(errors.name)}
				sx={styles.formControl}
			>
				<FormLabel>{t("contact.form.name.label")}</FormLabel>
				<Input
					placeholder={t("contact.form.name.placeholder")}
					{...register("name")}
				/>

				<FormErrorMessageI18n error={errors.name} />
			</FormControl>

			<FormControl
				isInvalid={Boolean(errors.email)}
				sx={styles.formControl}
			>
				<FormLabel>{t("contact.form.email.label")}</FormLabel>
				<Input
					placeholder={t("contact.form.email.placeholder")}
					{...register("email")}
				/>

				<FormErrorMessageI18n error={errors.email} />
			</FormControl>

			<FormControl
				isInvalid={Boolean(errors.message)}
				sx={styles.formControl}
			>
				<FormLabel>{t("contact.form.message.label")}</FormLabel>
				<Controller
					control={control}
					name="message"
					render={({ field }) => (
						<Textarea
							maxLength={500}
							placeholder={t("contact.form.message.placeholder")}
							resize="none"
							{...field}
						/>
					)}
				/>

				<FormErrorMessageI18n error={errors.message} />
			</FormControl>
		</ContactForm>
	);
};

// 🔬 e2e: contact.spec.ts
