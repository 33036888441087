import {
	chakra,
	InputProps,
	Popover,
	PopoverContent,
	PopoverProps,
	PopoverTrigger,
	useDisclosure,
	useMultiStyleConfig,
	useOutsideClick,
} from "@chakra-ui/react";
import { format } from "date-fns/format";
import React, { useMemo, useRef, useState } from "react";
import {
	DayPicker,
	MonthsDropdown,
	PropsBase,
	PropsSingle,
	YearsDropdown,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useTranslatedString } from "src/i18n/i18n";
import { DatePickerInput } from "./DatePickerInput";
import { dateFormat, locale } from "./utils";

type DatePickerProps = PopoverProps & {
	name?: string;
	onChange?: (d?: Date) => void;
	onBlur?: VoidFunction;
	dayPickerOptions?: Partial<PropsBase & PropsSingle>;
	selected?: Date;
	inputProps?: InputProps;
};

export const DatePicker: React.FC<DatePickerProps> = ({
	onChange,
	onBlur,
	selected,
	name,
	dayPickerOptions,
	inputProps,
	...rest
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("DatePicker");

	const today = useMemo(() => new Date(), []);

	const ref = useRef<HTMLInputElement | null>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [month, setMonth] = useState(new Date());
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		selected,
	);
	const [inputValue, setInputValue] = useState(
		selected ? format(selected, dateFormat) : "",
	);

	const handleDayPickerSelect = (date: Date | undefined) => {
		if (date) {
			setSelectedDate(date);
			setMonth(date);
			setInputValue(format(date, dateFormat));
		} else {
			setInputValue("");
			setSelectedDate(undefined);
		}
	};

	useOutsideClick({
		ref,
		handler: () => {
			onClose();
			isOpen && onBlur?.();
		},
	});

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter" || event.key === " ") {
			event.preventDefault();
			onOpen();
		}
	};

	return (
		<chakra.div __css={styles.wrapper} ref={ref}>
			<Popover
				isOpen={isOpen}
				onClose={onClose}
				closeOnBlur={true}
				placement="bottom"
				matchWidth={true}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={false}
				{...rest}
			>
				<PopoverTrigger>
					<DatePickerInput
						inputProps={{ ...inputProps, onKeyDown: handleKeyDown }}
						isOpen={isOpen}
						onOpen={onOpen}
						value={inputValue}
					/>
				</PopoverTrigger>
				<PopoverContent>
					<DayPicker
						mode="single"
						components={{
							/* eslint-disable-next-line @typescript-eslint/naming-convention */
							MonthsDropdown: (props) => (
								<chakra.div __css={styles.dropdownWrapper}>
									<MonthsDropdown {...props} />
								</chakra.div>
							),
							/* eslint-disable-next-line @typescript-eslint/naming-convention */
							YearsDropdown: (props) => (
								<chakra.div __css={styles.dropdownWrapper}>
									<YearsDropdown {...props} />
								</chakra.div>
							),
						}}
						startMonth={today}
						locale={locale}
						required
						onDayClick={(day) => {
							onClose();
							onBlur?.();
							onChange?.(day);
						}}
						month={month}
						onMonthChange={setMonth}
						selected={selectedDate}
						onSelect={handleDayPickerSelect}
						fixedWeeks
						labels={{
							labelNext: () => t("datepicker.nextMonth"),
							labelPrevious: () => t("datepicker.previousMonth"),
						}}
						numberOfMonths={1}
						{...dayPickerOptions}
					/>
				</PopoverContent>
			</Popover>
		</chakra.div>
	);
};

// 🔬 e2e: contact.spec.ts
