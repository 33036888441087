import { useTimeoutEffect } from "@react-hookz/web";
import React, { ReactNode, useMemo } from "react";
import { goToHome, goToLoginPage } from "src/lib/auth";
import { useCustomerAccountInfo, useLoginInfo } from "src/queries/emil/account";

const roles = [
	// everybody can see this
	"guest",
	// logged in account-holders can see this
	"accountHolder",

	// custom:role = customer in JWT
	"customer",
	// account.type from customers API response
	"customer:person",
	"customer:org",

	// custom:role = user in JWT
	"user",
	// custom:tenant_hierarchy in JWT
	"user:propertyManager",
	"user:broker",
] as const;

export type Role = (typeof roles)[number];

export const isRole = (role: unknown): role is Role => {
	return (
		Boolean(role) &&
		typeof role === "string" &&
		roles.includes(role as Role)
	);
};

export type AuthGuardProps = {
	accessLevels: Array<Role>;
	redirectOnFail?: boolean;
	children: ReactNode;
};

export const AuthGuard: React.FC<AuthGuardProps> = ({
	accessLevels,
	children,
	redirectOnFail = false,
}) => {
	const { data: remoteInfo, isFetching } = useCustomerAccountInfo();
	const loginInfo = useLoginInfo();

	const hasLoginToken = loginInfo?.email !== undefined;

	const isAllowedSync = useMemo(() => {
		// this is the most permissive role
		if (accessLevels.includes("guest")) return true;

		return false;
	}, [accessLevels]);

	const isAllowedAsync = useMemo(() => {
		const isLoggedIn = Boolean(
			remoteInfo?.account?.email ?? remoteInfo?.email,
		);

		if (!isLoggedIn) return false;

		return accessLevels.some((accessLevel) => {
			if (accessLevel === "accountHolder") return true;

			if (accessLevel === "user") return Boolean(remoteInfo?.isUser);
			// These users don't have an account object, so we can't do the simple access level check below
			if (accessLevel === "user:broker")
				return Boolean(remoteInfo?.isBroker);
			if (accessLevel === "user:propertyManager")
				return Boolean(remoteInfo?.isPropertyManager);

			if (accessLevel === "customer")
				return Boolean(remoteInfo?.isCustomer);

			const customerAccessLevel = accessLevel.split(":")[1];

			const isCorrectCustomerAccessLevel =
				remoteInfo?.account?.type === customerAccessLevel;

			return isCorrectCustomerAccessLevel;
		});
	}, [accessLevels, remoteInfo]);

	const isAllowed = isAllowedSync || isAllowedAsync;

	// Perform redirect if needed
	useTimeoutEffect(() => {
		if (!redirectOnFail || isFetching || isAllowed) {
			return;
		}

		if (hasLoginToken) {
			goToHome();
		} else {
			goToLoginPage(false);
		}
	}, 1000);

	return isAllowed ? <>{children}</> : null;
};

// 🔬 e2e: authguard.spec.ts & RTL: AuthGuard.test.tsx
