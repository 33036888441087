import {
	chakra,
	Container,
	Input,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useMultiStyleConfig,
	VisuallyHidden,
} from "@chakra-ui/react";
import { FAQPageJsonLd } from "next-seo";
import React from "react";
import { FaqsFragment, Maybe } from "src/__generated__/cms-schema.codegen";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import {
	FaqAccordion,
	FaqAccordionItem,
} from "src/components/FaqAccordion/FaqAccordion";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import { TopicSelector } from "src/components/TopicSelector/TopicSelector";
import { useTranslatedString } from "src/i18n/i18n";
import useFaqFilter from "./hooks";

export type Faqs = Array<FaqsFragment>;

export type FaqSectionProps = {
	title: Maybe<string>;
	subtitle: Maybe<string>;
	text: Maybe<string>;
	faqs: Faqs;
	hasSearch: boolean;
};

const QuestionsAndAnswers: React.FC<{ faq: FaqsFragment }> = ({ faq }) => (
	<FaqAccordion>
		{faq.questions.map(({ id, question, answer, number }) => (
			<FaqAccordionItem
				key={id}
				label={question}
				number={number ?? undefined}
			>
				<DatoRichTextContent html={answer} />
			</FaqAccordionItem>
		))}
	</FaqAccordion>
);

export const FaqSection: React.FC<FaqSectionProps> = ({
	title,
	subtitle,
	text,
	faqs,
	hasSearch,
}) => {
	const styles = useMultiStyleConfig("FaqSection");
	const t = useTranslatedString();

	const {
		searchTerm,
		setSearchTerm,
		filteredTopics,
		currentTopic,
		setCurrentTopic,
		filteredFaqsByFamily,
	} = useFaqFilter(faqs);

	if (!faqs[0]) {
		return null;
	}

	return (
		<React.Fragment>
			<FAQPageJsonLd
				mainEntity={faqs.flatMap((faq) =>
					faq.questions.map((question) => ({
						questionName: question.question,
						acceptedAnswerText: question.answer,
					})),
				)}
			/>
			<chakra.div __css={styles.wrapper}>
				<Container maxW="container.lg">
					<chakra.div __css={styles.titleContainer}>
						{title && title.length > 0 && (
							<Hx sx={styles.title}>{title}</Hx>
						)}
						{subtitle && subtitle.length > 0 && (
							<chakra.p __css={styles.subtitle}>
								{subtitle}
							</chakra.p>
						)}
						{text && text.length > 0 && (
							<chakra.p __css={styles.text}>{text}</chakra.p>
						)}
						{hasSearch && (
							<>
								<VisuallyHidden>
									<chakra.label htmlFor="searchTerm">
										{t("faqSection.search")}
									</chakra.label>
								</VisuallyHidden>
								<Input
									id="searchTerm"
									type="search"
									value={searchTerm}
									onChange={(event) =>
										setSearchTerm(event.target.value)
									}
									placeholder={t("faqSection.searchTerm")}
									sx={styles.input}
								/>
							</>
						)}
					</chakra.div>
				</Container>
				{hasSearch && filteredTopics.length > 0 && (
					<TopicSelector
						currentTopic={currentTopic}
						setCurrentTopic={setCurrentTopic}
						allTopics={filteredTopics}
						sx={styles.topicSelector}
						buttonWrapperStyle={{
							flexDir: "row",
							flexWrap: "nowrap",
						}}
					/>
				)}
				<Container maxW="container.lg">
					{hasSearch ? (
						filteredFaqsByFamily.length > 0 ? (
							<chakra.div>
								{filteredFaqsByFamily.map((faq) => {
									const { id, title, subtitle } = faq;

									return (
										<HeadingLevelBoundary key={id}>
											{title && (
												<Hx sx={styles.faqTitle}>
													{title}
												</Hx>
											)}
											{subtitle && (
												<chakra.span
													__css={styles.faqSubtitle}
												>
													{subtitle}
												</chakra.span>
											)}
											<QuestionsAndAnswers
												key={id}
												faq={faq}
											/>
										</HeadingLevelBoundary>
									);
								})}
							</chakra.div>
						) : (
							<chakra.div
								pt={{ base: 5, lg: 10 }}
								textAlign="center"
							>
								{t("faqSection.notFound")}
							</chakra.div>
						)
					) : (
						<>
							{faqs.length > 1 ? (
								<Tabs variant="faq">
									<TabList>
										{faqs.map(({ id, title, subtitle }) => (
											<Tab key={id}>
												{title && title.length > 0
													? title
													: subtitle}
											</Tab>
										))}
									</TabList>
									<TabPanels>
										{faqs.map((faq) => (
											<TabPanel key={faq.id}>
												<QuestionsAndAnswers
													faq={faq}
												/>
											</TabPanel>
										))}
									</TabPanels>
								</Tabs>
							) : (
								<QuestionsAndAnswers faq={faqs[0]} />
							)}
						</>
					)}
				</Container>
			</chakra.div>
		</React.Fragment>
	);
};

// 🔬 story
