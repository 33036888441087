import {
	ImgixParams,
	ImgixParamsCrop,
	ImgixParamsFit,
	ImgixParamsAuto,
} from "src/__generated__/cms-schema.codegen";

const imageQualityMin = 20;
const imageQualityMax = 60;
const imageQualityMaxWidth = 1440; // ideally the biggest image width on the whole site

/**
 * These functions use inverse quality scaling: make small images use better
 * and large images lower quality.
 *
 * A large image (`imageQualityMaxWidth` wide) will be only `imageQualityMin` % quality
 * small images can go up to just under 60% quality.
 */
const getImgixParamsByWidth = (width: number): Partial<ImgixParams> => {
	const q = Math.round(
		imageQualityMin +
			(1 - Math.min(width / imageQualityMaxWidth, 1)) *
				(imageQualityMax - imageQualityMin),
	);

	return {
		auto: [ImgixParamsAuto.format, ImgixParamsAuto.compress],
		w: width,
		fit: ImgixParamsFit.fill,
		crop: [ImgixParamsCrop.edges],
		q,
	};
};

/**
 * Note: the aspect ratio is always `width / height` (not the other way round).
 */
const getImgixParamsByWidthAndAspectRatio = ({
	width,
	fileAspectRatio: aspectRatio,
}: {
	width: number;
	fileAspectRatio: number;
}): Partial<ImgixParams> => {
	return {
		...getImgixParamsByWidth(width),
		h: Math.floor(width / aspectRatio),
		fit: ImgixParamsFit.crop,
		crop: [ImgixParamsCrop.focalpoint],
	};
};

export const IMGIX_PARAMS = {
	heroSlideImage: getImgixParamsByWidthAndAspectRatio({
		fileAspectRatio: 3 / 4,
		width: 1024,
	}),
	contentImage: getImgixParamsByWidth(1024),
	contentWideImage: getImgixParamsByWidthAndAspectRatio({
		fileAspectRatio: 2 / 1,
		width: 1280,
	}),
	videoThumbnailImage: getImgixParamsByWidth(512),
	contactImage: getImgixParamsByWidth(168),
} as const;

// 🔬 TBD: Please evaluate
