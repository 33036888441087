import {
	Button,
	ButtonProps,
	ChakraProps,
	chakra,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import uniqBy from "lodash/uniqBy";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useTranslatedString } from "src/i18n/i18n";
import { isString } from "src/lib/utils";
import { Hx } from "../Heading/Heading";

export type Topic = { name: string; slug: string; color: string };

export type TopicSelectorProps = {
	topicSelectorText?: string;
	allTopics: Array<Topic>;
	currentTopic: string;
	setCurrentTopic: (v: string) => void;
	buttonWrapperStyle?: ChakraProps;
} & ChakraProps;

export const useTopicSelector = (
	productFamilies: Array<Topic>,
	allTopicsLabel?: string,
) => {
	const t = useTranslatedString();
	const router = useRouter();
	const { topic } = router.query;

	const allTopics = uniqBy(
		[
			{
				name: allTopicsLabel ?? t("blogpost.allTopics.name"),
				slug: t("blogpost.allTopics.slug"),
				color: "brand.dark",
			},
			...productFamilies.map(({ name, slug, color }) => ({
				name,
				slug,
				color,
			})),
		],
		"slug",
	);

	const [currentTopic, setCurrentTopic] = useState<string>(
		t("blogpost.allTopics.slug"),
	);

	// makes sure that ?filter=topic is set to the correct value
	useEffect(() => {
		if (isString(topic)) {
			setCurrentTopic(topic);
		}
	}, [topic]);

	return { allTopics, currentTopic, setCurrentTopic };
};

const TopicButton: React.FC<
	{
		topic: Topic;
		currentTopic: string;
	} & ButtonProps
> = ({ id, topic, currentTopic, onClick }) => {
	const { slug, name, color } = topic;
	const styles = useMultiStyleConfig("TopicSelector", {
		isCurrent: slug === currentTopic,
		color,
	});

	return (
		<Button id={id} sx={styles.button} onClick={onClick}>
			{name}
		</Button>
	);
};

export const TopicSelector: React.FC<TopicSelectorProps> = ({
	topicSelectorText,
	allTopics,
	currentTopic,
	setCurrentTopic,
	buttonWrapperStyle,
	...props
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("TopicSelector");
	const router = useRouter();
	const onClick = useCallback(
		(slug: string) => {
			const { pageOrProductFamilySlug } = router.query;

			// only push to /blog?topic=something if the current URL includes the /blog path
			if (pageOrProductFamilySlug === t("blog.urlSlug")) {
				router.push(`/${t("blog.urlSlug")}?topic=${slug}`, undefined, {
					shallow: true,
				});
			}

			setCurrentTopic(slug);
		},
		[router, t, setCurrentTopic],
	);

	return (
		<chakra.div __css={styles.wrapper} {...props}>
			{topicSelectorText && topicSelectorText.length > 0 && (
				<Hx fontFamily="sans" fontSize="2xl" textAlign="center">
					{topicSelectorText}
				</Hx>
			)}
			<chakra.div
				__css={{ ...styles.buttonWrapper, ...buttonWrapperStyle }}
			>
				{allTopics.map((t) => (
					<TopicButton
						id={t.slug}
						key={t.slug}
						topic={t}
						currentTopic={currentTopic}
						onClick={() => onClick(t.slug)}
					/>
				))}
			</chakra.div>
		</chakra.div>
	);
};

// 🔬 e2e: story
