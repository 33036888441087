import {
	chakra,
	ChakraProps,
	FormHelperText,
	Input,
	Select,
	useMultiStyleConfig,
	VisuallyHidden,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { isWeekend, addMonths } from "date-fns";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "src/components/DatePicker/DatePicker";
import { FormErrorMessageI18n } from "src/components/FormErrorMessageI18n/FormErrorMessageI18n";
import { Hx } from "src/components/Heading/Heading";
import { Textarea } from "src/components/Textarea/Textarea";
import { FormControl, FormLabel } from "src/components/forms";
import { useTranslatedString } from "src/i18n/i18n";
import { Clock, Phone } from "src/icons";
import { z } from "src/lib/zod";
import { TypeOf } from "zod";
import {
	commonEmailPropsSchema,
	ContactForm,
	ContactFormProductSelect,
	ContactFormTitle,
} from "./ContactForm";

const TIME_SLOTS = ["08:00-11:00", "11:00-13:00", "13:00-17:00"] as const;

const callbackSchema = commonEmailPropsSchema.extend({
	name: z.string().min(1),
	phone: z.string().min(8),
	timeSlot: z.enum(TIME_SLOTS),
	date: z.date(),
	message: z.string().max(500).optional(),
});

export const CallbackForm: React.FC<ChakraProps> = (props) => {
	const styles = useMultiStyleConfig("ContactForm");
	const t = useTranslatedString();
	const today = useMemo(() => new Date(), []);

	const form = useForm<TypeOf<typeof callbackSchema>>({
		resolver: zodResolver(callbackSchema),
		mode: "onTouched",
		defaultValues: {
			emailSubject: t("contact.subject.callback"),
			timeSlot: TIME_SLOTS[0],
			date: isWeekend(today) === false ? today : undefined,
		},
	});

	const {
		register,
		control,
		formState: { errors, isValid },
	} = form;

	return (
		<ContactForm
			type="callback"
			isValid={isValid}
			buttonText={t("contact.send")}
			form={form}
			{...props}
		>
			<ContactFormTitle>
				<Phone />
				<Hx size="h3">{t("contact.callbackService")}</Hx>
			</ContactFormTitle>
			<ContactFormProductSelect />
			<FormControl
				isInvalid={Boolean(errors.name)}
				sx={styles.formControl}
			>
				<FormLabel>{t("contact.form.name.label")}</FormLabel>
				<Input
					placeholder={t("contact.form.name.placeholder")}
					{...register("name")}
				/>

				<FormErrorMessageI18n error={errors.name} />
			</FormControl>
			<FormControl
				isInvalid={Boolean(errors.phone)}
				sx={styles.formControl}
			>
				<FormLabel>{t("contact.form.telephone.label")}</FormLabel>

				<Input
					placeholder={t("contact.form.telephone.placeholder")}
					{...register("phone")}
				/>

				<FormHelperText sx={styles.formHelperText}>
					<span>{t("contact.form.telephone.help.text")}</span>
					<chakra.span display="block">
						<strong>
							{t("contact.form.telephone.help.countryCode")}
						</strong>
						{t("contact.form.telephone.help.number")}
					</chakra.span>
				</FormHelperText>
				<FormErrorMessageI18n error={errors.phone} />
			</FormControl>
			<chakra.fieldset>
				<chakra.legend __css={styles.userAvailableLabel}>
					{t("contact.userAvailable")}
				</chakra.legend>

				<chakra.div sx={styles.timeAndDate}>
					<FormControl
						isInvalid={Boolean(errors.date)}
						sx={styles.formControl}
					>
						<VisuallyHidden>
							<FormLabel>
								{t("contact.form.date.label")}
							</FormLabel>
						</VisuallyHidden>

						<Controller
							control={control}
							name="date"
							render={({
								field: { onChange, onBlur, value },
							}) => (
								<DatePicker
									onChange={onChange}
									onBlur={onBlur}
									selected={value}
									inputProps={{
										placeholder: t(
											"contact.form.date.placeholder",
										),
									}}
									dayPickerOptions={{
										endMonth: addMonths(today, 2),
										disabled: [
											{
												before: today,
												after: addMonths(today, 2),
											},
											{ dayOfWeek: [0, 6] },
										],
									}}
								/>
							)}
						/>

						<FormErrorMessageI18n error={errors.date} />
					</FormControl>
					<FormControl
						isInvalid={Boolean(errors.timeSlot)}
						sx={styles.formControl}
					>
						<VisuallyHidden>
							<FormLabel>
								{t("contact.form.timeSlot.label")}
							</FormLabel>
						</VisuallyHidden>

						<Controller
							control={control}
							name="timeSlot"
							render={({ field }) => (
								<Select
									icon={<Clock />}
									variant="filled"
									{...field}
								>
									{TIME_SLOTS.map((timeslot) => (
										<option key={timeslot} value={timeslot}>
											{timeslot}
										</option>
									))}
								</Select>
							)}
						/>
						<FormHelperText sx={styles.formHelperText}>
							{t("contact.form.timeSlot.help")}
						</FormHelperText>
						<FormErrorMessageI18n error={errors.timeSlot} />
					</FormControl>
				</chakra.div>
			</chakra.fieldset>
			<FormControl
				isInvalid={Boolean(errors.message)}
				sx={styles.formControl}
			>
				<FormLabel>
					{t("contact.form.message.label.optional")}
				</FormLabel>
				<Controller
					control={control}
					name="message"
					render={({ field }) => (
						<Textarea
							maxLength={500}
							placeholder={t("contact.form.message.placeholder")}
							resize="none"
							{...field}
						/>
					)}
				/>

				<FormErrorMessageI18n error={errors.message} />
			</FormControl>
		</ContactForm>
	);
};

// 🔬 e2e: contact.spec.ts
