import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	useMultiStyleConfig,
	useBreakpointValue,
	TabsProps,
	HeadingProps,
	TabPanelsProps,
	TabPanelProps,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import { createContext } from "src/lib/react";

export type TabsOrColumnsProps = Omit<TabsProps, "children"> & {
	columns: Array<{ id: string; title: string }>;
	children: ReactNode;
};

export const desktopBreakpoint = "md";

const [TabsOrColumnsContext, useTabsOrColumnsContext] = createContext<{
	isTabbed: boolean;
	styles: ReturnType<typeof useMultiStyleConfig>;
}>("TabsOrColumnsContext");

export const TabsOrColumns: React.FC<TabsOrColumnsProps> = ({
	columns,
	children,
	...rest
}) => {
	const isTabbed =
		useBreakpointValue(
			{
				base: true,
				[desktopBreakpoint]: false,
			},
			{ fallback: desktopBreakpoint },
		) ?? false;

	const styles = useMultiStyleConfig("TabsOrColumns", {
		desktopBreakpoint,
		isTabbed,
	});

	return (
		<TabsOrColumnsContext.Provider value={{ isTabbed, styles }}>
			<Tabs variant="tabsOrColumns" {...rest}>
				{children}
			</Tabs>
		</TabsOrColumnsContext.Provider>
	);
};

export const TabsOrColumnsPanels: React.FC<
	TabPanelsProps & {
		children: ReactNode;
		showDivider?: boolean;
	}
> = ({ children, showDivider = true, ...rest }) => {
	const { isTabbed } = useTabsOrColumnsContext();

	const styles = useMultiStyleConfig("TabsOrColumns", {
		desktopBreakpoint,
		showDivider,
		isTabbed,
	});

	return (
		<TabPanels sx={styles.columns} {...rest}>
			{children}
		</TabPanels>
	);
};

export const TabsOrColumnsDesktopTitle: React.FC<
	HeadingProps & { title: string }
> = ({ title, ...rest }) => {
	const { styles } = useTabsOrColumnsContext();

	return (
		<Hx size="h3" sx={styles.columnTitle} {...rest}>
			{title}
		</Hx>
	);
};
export const TabsOrColumnsPanelMobileTablist: React.FC<{
	columns: Array<{ id: string; title: string }>;
}> = ({ columns }) => {
	const { styles } = useTabsOrColumnsContext();

	return (
		<TabList sx={styles.tabs} data-testid="toc-mobile">
			{columns.map((column) => (
				<Tab sx={styles.tab} key={column.id}>
					{column.title}
				</Tab>
			))}
		</TabList>
	);
};

export const TabsOrColumnsPanel: React.FC<
	TabPanelProps & { children: ReactNode }
> = ({ children, ...rest }) => {
	const { styles } = useTabsOrColumnsContext();

	return (
		<TabPanel sx={styles.column} paddingTop={99} {...rest}>
			<HeadingLevelBoundary>{children}</HeadingLevelBoundary>
		</TabPanel>
	);
};

// 🔬 ladle -> e2e
