import { IconProps } from "@chakra-ui/react";
import React from "react";
import { Plus as PlusIcon, Minus as MinusIcon } from ".";

type PlusProps = Omit<IconProps, "css"> & {
	isActive?: boolean;
};

export const PlusMinus: React.FC<PlusProps> = ({
	isActive = false,
	width = "1.3rem",
	...rest
}) => {
	return isActive ? (
		<MinusIcon width={width} height={width} {...rest} />
	) : (
		<PlusIcon width={width} height={width} {...rest} />
	);
};
