import React from "react";
import { useGetContactImageQuery } from "src/__generated__/client.codegen";
import { DemoSectionFragment } from "src/__generated__/cms-schema.codegen";
import { Contact, ContactFormsWrapper } from "src/components/Contact/Contact";
import { useSiteSettings } from "src/lib/hooks";
import { IMGIX_PARAMS } from "src/lib/imgix";
import { DemoForm } from "../ContactForm/DemoForm";

export type DemoSectionProps = Omit<DemoSectionFragment, "__typename">;

export const DemoSection: React.FC<DemoSectionProps> = ({
	title,
	subtitle,
	contact,
}) => {
	const { locale } = useSiteSettings();
	const { data } = useGetContactImageQuery({
		locale,
		id: contact.id,
		contactImage: IMGIX_PARAMS.contactImage,
	});

	const photo = data?.contact?.photo ?? null;

	return (
		<Contact
			title={title}
			subtitle={subtitle}
			contact={contact}
			photo={photo}
		>
			<ContactFormsWrapper rightForm={<DemoForm />} />
		</Contact>
	);
};

// 🔬 TBD: Please evaluate
