import { useMultiStyleConfig, VStack } from "@chakra-ui/react";
import { LinkCard, LinkCardProps } from "src/components/LinkCard/LinkCard";

export type LinkCardsSectionProps = {
	cards: Array<LinkCardProps>;
};

export const LinkCardsSection: React.FC<LinkCardsSectionProps> = ({
	cards,
}) => {
	const styles = useMultiStyleConfig("LinkCardsSection");

	return (
		<VStack sx={styles.wrapper}>
			{cards.map(({ ...props }) => {
				return <LinkCard key={props.id} {...props} />;
			})}
		</VStack>
	);
};

// 🔬 e2e: story
