import {
	chakra,
	ChakraProps,
	Container,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type BubbleContainerProps = ChakraProps & {
	children: ReactNode;
	isFirst: boolean;
	isLast: boolean;
};

export const BubbleContainer: React.FC<BubbleContainerProps> = ({
	children,
	isFirst,
	isLast,
	...rest
}) => {
	const styles = useMultiStyleConfig("BubbleContainer", { isFirst, isLast });

	return (
		<chakra.section __css={styles.wrapper} {...rest}>
			<Container sx={styles.inner} maxW="container.xl">
				<Container
					sx={styles.content}
					maxW={{ base: "none", lg: "container.lg" }}
				>
					{children}
				</Container>
			</Container>
		</chakra.section>
	);
};

// 🔬 TBD: e2e
