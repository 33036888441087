import { chakra, Grid, GridItem, useMultiStyleConfig } from "@chakra-ui/react";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";
import { LogoFunk } from "src/icons";

type AboutSectionProps = {
	structuredText: StructuredTextData;
};

export const AboutSection: React.FC<AboutSectionProps> = ({
	structuredText,
}) => {
	const styles = useMultiStyleConfig("AboutSection");

	return (
		<chakra.div __css={styles.wrapper}>
			<Grid gap="grid.gap" templateColumns="repeat(12, 1fr)">
				<GridItem colSpan={{ base: 12, lg: 4 }} sx={styles.left}>
					<LogoFunk sx={styles.logo} />
				</GridItem>
				<GridItem colSpan={{ base: 12, lg: 7, xl: 6 }}>
					<chakra.div __css={styles.structuredText}>
						<StructuredText data={structuredText} />
					</chakra.div>
				</GridItem>
			</Grid>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
