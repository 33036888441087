import {
	Button,
	ButtonProps,
	IconButton,
	IconButtonProps,
} from "@chakra-ui/react";

type Props<T> = Omit<T, "isDisabled" | "disabled" | "type"> & {
	isValid: boolean;
	forceDisabled?: boolean;
};

export const FormSubmitButton: React.FC<Props<ButtonProps>> = ({
	isValid,
	forceDisabled = false,
	...props
}) => {
	return (
		<Button
			{...(isValid ? {} : { "data-form-submit-disabled": true })}
			isDisabled={forceDisabled}
			type="submit"
			{...props}
		/>
	);
};

export const FormSubmitIconButton: React.FC<Props<IconButtonProps>> = ({
	isValid,
	forceDisabled = false,
	...props
}) => {
	return (
		<IconButton
			{...(isValid ? {} : { "data-form-submit-disabled": true })}
			isDisabled={forceDisabled}
			type="submit"
			{...props}
		/>
	);
};

// 🔬 TBD: Please evaluate
