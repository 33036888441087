import { chakra, Container, useMultiStyleConfig, Wrap } from "@chakra-ui/react";
import React from "react";
import { Hx } from "src/components/Heading/Heading";

type ServiceBenefitsProps = {
	children: React.ReactNode;
	totalCount: number;
	title?: string;
	variant?: "alt";
};

const ServiceBenefits: React.FC<ServiceBenefitsProps> = ({
	children,
	totalCount,
	title,
	variant,
}) => {
	const columns =
		// 3, 6, 9, … => 3 columns
		totalCount % 3 === 0
			? 3
			: // 2, 4, 8, … => 2 columns
				totalCount % 2 === 0
				? 2
				: // … all others => 3 columns
					3;
	const styles = useMultiStyleConfig("ServiceBenefits", { variant, columns });

	return (
		<chakra.div __css={styles.wrapper}>
			<Container maxW="container.xl">
				{title && (
					<Hx sx={styles.title} size="h2">
						{title}
					</Hx>
				)}
				<Wrap
					align="stretch"
					justify="center"
					spacing={0}
					shouldWrapChildren={true}
					sx={styles.inner}
				>
					{children}
				</Wrap>
			</Container>
		</chakra.div>
	);
};

export { ServiceBenefits };

// 🔬 TBD: Please evaluate
