import React from "react";
import { useGetContactImageQuery } from "src/__generated__/client.codegen";
import { ContactSectionFragment } from "src/__generated__/cms-schema.codegen";
import { Contact, ContactFormsWrapper } from "src/components/Contact/Contact";
import { useSiteSettings } from "src/lib/hooks";
import { IMGIX_PARAMS } from "src/lib/imgix";
import { CallbackForm } from "../ContactForm/CallbackForm";
import { EmailForm } from "../ContactForm/EmailForm";

export type ContactSectionProps = Omit<ContactSectionFragment, "__typename">;

export const ContactSection: React.FC<ContactSectionProps> = ({
	title,
	subtitle,
	contact,
}) => {
	const { locale } = useSiteSettings();
	const { data } = useGetContactImageQuery({
		locale,
		id: contact.id,
		contactImage: IMGIX_PARAMS.contactImage,
	});

	const photo = data?.contact?.photo ?? null;

	return (
		<Contact
			title={title}
			subtitle={subtitle}
			contact={contact}
			photo={photo}
		>
			<ContactFormsWrapper
				leftForm={<CallbackForm />}
				rightForm={<EmailForm />}
			/>
		</Contact>
	);
};

// 🔬 TBD: Please evaluate
