import {
	chakra,
	Grid,
	GridItem,
	useMultiStyleConfig,
	VStack,
	HStack,
} from "@chakra-ui/react";
import { InlineLinkButtonFragment } from "src/__generated__/client.codegen";
import { isValueOfEnumFactory } from "src/lib/utils";
import { getIcon } from "../CMSButton/CMSButtonLabel";
import { CMSLinkButton } from "../CMSButton/CMSLinkButton";
import { DatoAsset, DatoAssetData } from "../Dato/DatoAsset";
import { Hx } from "../Heading/Heading";

export const variants = ["left", "right"] as const;

export type Variant = (typeof variants)[number];

const isVariant = isValueOfEnumFactory(variants);

export const readVariant = (v: unknown): Variant => {
	return isVariant(v) ? v : "left";
};

export type LinkCardProps = {
	id: string;
	title: string;
	variant: Variant;
	image: DatoAssetData;
	links: Array<InlineLinkButtonFragment>;
};

export const LinkCard: React.FC<LinkCardProps> = ({
	title,
	variant,
	image,
	links,
}) => {
	const styles = useMultiStyleConfig("LinkCard", {
		variant,
	});

	return (
		<chakra.div __css={styles.wrapper}>
			<chakra.div __css={styles.imageWrapper}>
				<DatoAsset data={image} />
			</chakra.div>
			<VStack sx={styles.linkWrapper}>
				<Hx color="brand.dark" size="h2">
					{title}
				</Hx>
				<Grid
					sx={styles.grid}
					templateColumns={{
						base: "1fr",
						md: links.length > 5 ? "1fr 1fr" : "1fr",
					}}
				>
					{links.map((link) => (
						<Link key={link.id} {...{ link }} />
					))}
				</Grid>
			</VStack>
		</chakra.div>
	);
};

const Link = ({ link }: { link: InlineLinkButtonFragment }) => {
	const styles = useMultiStyleConfig("LinkCard");
	const { leftIcon, text, label } = link;

	return (
		<GridItem>
			<CMSLinkButton {...link} sx={styles.link}>
				<HStack w="full">
					{leftIcon && (
						<chakra.span __css={styles.icon}>
							{getIcon(leftIcon.name)}
						</chakra.span>
					)}
					<VStack sx={styles.label}>
						<chakra.span>{label}</chakra.span>
						{text && (
							<chakra.span __css={styles.subtitle}>
								{text}
							</chakra.span>
						)}
					</VStack>
				</HStack>
			</CMSLinkButton>
		</GridItem>
	);
};

// 🔬 e2e: story
