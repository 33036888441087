import { Divider, chakra, useMultiStyleConfig } from "@chakra-ui/react";
import { useMemo } from "react";
import {
	CMSLinkButton,
	CMSLinkButtonProps,
} from "src/components/CMSButton/CMSLinkButton";
import { useTranslatedString } from "src/i18n/i18n";

type Link = CMSLinkButtonProps;

export type NavigationSectionProps = { links: Array<Link> };

const NavigationItem: React.FC<CMSLinkButtonProps> = ({ ...props }) => {
	const styles = useMultiStyleConfig("NavigationSection");

	const href =
		typeof window === "undefined" ? undefined : window.location.href;

	const isActive = useMemo(() => {
		const url = href ? new URL(href) : undefined;
		return url ? url.hash === props.jumpMark : false;
	}, [href, props.jumpMark]);

	return (
		<chakra.div __css={styles.linkWrapper}>
			<CMSLinkButton
				{...props}
				sx={{
					...styles.link,
					borderColor: isActive ? "brand.highlight" : "transparent",
				}}
			/>
		</chakra.div>
	);
};

export const NavigationSection: React.FC<NavigationSectionProps> = ({
	links,
}) => {
	const styles = useMultiStyleConfig("NavigationSection");
	const t = useTranslatedString();

	return (
		<chakra.div __css={styles.wrapper}>
			<chakra.div __css={styles.container}>
				<span>{t("tableOfContents")}</span>
				<chakra.div __css={styles.links}>
					{links.map(({ ...props }) => (
						<NavigationItem key={props.id} {...props} />
					))}
				</chakra.div>
			</chakra.div>
			<Divider borderWidth={1} borderColor="brand.light" />
		</chakra.div>
	);
};

// 🔬 e2e: story
