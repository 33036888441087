import { chakra, Container, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { BubbleContainer } from "src/components/BubbleContainer/BubbleContainer";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import { Hx } from "src/components/Heading/Heading";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";
import {
	TabsOrColumnsPanel,
	TabsOrColumns,
	TabsOrColumnsPanels,
	TabsOrColumnsPanelMobileTablist,
	TabsOrColumnsDesktopTitle,
	desktopBreakpoint,
} from "src/components/TabsOrColumns/TabsOrColumns";
import { useBubbleContainerContext } from "../renderSections";

type ItemProps = {
	title: string;
	id: string;
	text: string;
};

type Column = {
	id: string;
	title: string;
	items: Array<ItemProps>;
};

type Props = {
	intro?: StructuredTextData;
	columns: Array<Column>;
};

const FunctionalitySectionItem: React.FC<ItemProps> = ({ id, text, title }) => {
	const styles = useMultiStyleConfig("FunctionalitySection", {
		desktopBreakpoint,
	});

	return (
		<chakra.li className="functionality-section-item" __css={styles.item}>
			<Hx sx={styles.itemTitle} size="h5">
				<span>{title}</span>
			</Hx>
			<DatoRichTextContent sx={styles.itemText} html={text} />
		</chakra.li>
	);
};

export const FunctionalitySection: React.FC<Props> = ({ intro, columns }) => {
	const styles = useMultiStyleConfig("FunctionalitySection", {
		desktopBreakpoint,
	});

	const bubbleProps = useBubbleContainerContext();

	return (
		<chakra.div sx={styles.wrapper}>
			{intro && (
				<Container maxW="container.xl" px="0">
					<chakra.div __css={styles.intro}>
						<StructuredText data={intro} />
					</chakra.div>
				</Container>
			)}

			<TabsOrColumns
				columns={columns.map((c) => ({
					id: c.id,
					title: c.title,
				}))}
			>
				<Container maxW="container.xl" px="0" data-axe-ignore>
					<TabsOrColumnsPanelMobileTablist columns={columns} />
					<TabsOrColumnsPanels
						showDivider={false}
						style={{ marginTop: 0, paddingTop: 0 }}
					>
						{columns.map((column, index) => (
							<TabsOrColumnsPanel key={column.id}>
								<TabsOrColumnsDesktopTitle
									title={column.title}
								/>
							</TabsOrColumnsPanel>
						))}
					</TabsOrColumnsPanels>
				</Container>

				<Container maxW="container.xl" px="0">
					<BubbleContainer
						backgroundColor="transparent"
						{...bubbleProps}
					>
						<TabsOrColumnsPanels
							// inline styles to override specificity, sorry
							style={{ paddingTop: 0, marginTop: 0 }}
						>
							{columns.map((column, index) => (
								<TabsOrColumnsPanel
									// inline styles to override specificity, sorry
									style={{ paddingTop: 0 }}
									key={column.id}
									data-column-index={index}
								>
									<chakra.ul __css={styles.column}>
										{column.items.map((item) => (
											<FunctionalitySectionItem
												key={item.id}
												{...item}
											/>
										))}
									</chakra.ul>
								</TabsOrColumnsPanel>
							))}
						</TabsOrColumnsPanels>
					</BubbleContainer>
				</Container>
			</TabsOrColumns>
		</chakra.div>
	);
};

// 🔬 TBD: e2e
