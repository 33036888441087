import {
	AspectRatio,
	chakra,
	useBoolean,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { DatoAsset, DatoAssetData } from "src/components/Dato/DatoAsset";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import { Hx } from "src/components/Heading/Heading";
import { Info } from "src/icons";

export type FlipperProps = {
	title: string;
	text: string;
	icon: DatoAssetData;
};

export const Flipper: React.FC<FlipperProps> = ({ title, text, icon }) => {
	const [isClicked, { toggle: onClick }] = useBoolean(false);
	const [isHovered, { off: onMouseLeave, on: onMouseOver }] =
		useBoolean(false);
	const isFlipped = isHovered || isClicked;
	const styles = useMultiStyleConfig("Flipper", { isFlipped });

	return (
		<AspectRatio
			ratio={{ base: 1, xs: 1.41, sm: 3 / 4, md: 4 / 5 }}
			as="button"
			role="button"
			{...{
				onMouseOver,
				onMouseLeave,
				onClick: () => {
					onClick();
					onMouseLeave();
				},
				onFocus: onMouseOver,
				onBlur: onMouseLeave,
			}}
			__css={styles.wrapper}
		>
			<chakra.div sx={styles.inner}>
				<chakra.div __css={styles.cards}>
					<chakra.div __css={styles.front} aria-hidden>
						<chakra.div __css={styles.iconWrapper}>
							<DatoAsset sx={styles.icon} data={icon} alt="" />
						</chakra.div>
						<Hx sx={styles.title}>{title}</Hx>
						<Info sx={styles.moreInfo} />
					</chakra.div>

					<chakra.div __css={styles.back}>
						<Hx sx={styles.title}>{title}</Hx>
						<DatoRichTextContent
							__css={styles.backText}
							html={text}
						/>
					</chakra.div>
				</chakra.div>
			</chakra.div>
		</AspectRatio>
	);
};

// 🔬 TBD: RTL and e2e
