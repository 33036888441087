import {
	chakra,
	Link,
	Container,
	Flex,
	Input,
	useMultiStyleConfig,
	VisuallyHidden,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { BackgroundShape } from "src/components/BackgroundShape/BackgroundShape";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import { FormErrorMessageI18n } from "src/components/FormErrorMessageI18n/FormErrorMessageI18n";
import { FormSubmitIconButton } from "src/components/FormSubmitButton";
import { Hx } from "src/components/Heading/Heading";
import { FormControl, FormLabel } from "src/components/forms";
import { useTranslatedString } from "src/i18n/i18n";
import { Handshake, Submit } from "src/icons";
import { useGTMSubmitSignup } from "src/lib/gtm";
import { useNewsletterSignup } from "src/lib/useNewsletterSignup";
import { z } from "src/lib/zod";

type NewsletterSignupProps = {
	action: string;
	title: string;
	intro: string;
};

export const NewsletterSignup: React.FC<NewsletterSignupProps> = ({
	action,
	title,
	intro,
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("NewsletterSignup");
	const trackSuccesfulSignup = useGTMSubmitSignup();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<{ email: string }>({
		resolver: zodResolver(
			z.object({
				email: z.string().email(),
			}),
		),
		mode: "onTouched",
	});

	const { mutate, isLoading, isSuccess } = useNewsletterSignup(() => {
		trackSuccesfulSignup();
		reset();
	});

	return (
		<chakra.div __css={styles.wrapper} data-condition={isSuccess}>
			<BackgroundShape offset={55} />
			<Container maxW="container.md" sx={styles.inner}>
				<chakra.div __css={styles.success}>
					<Hx sx={styles.title}>{t("newsletter.success.title")}</Hx>
					<Handshake w="auto" h="10rem" mb={8} />
					<DatoRichTextContent
						__css={styles.intro}
						html={t("newsletter.success.text")}
					/>
				</chakra.div>
				<chakra.div __css={styles.formWrapper}>
					<Hx sx={styles.title}>{title}</Hx>
					<DatoRichTextContent __css={styles.intro} html={intro} />

					<form
						noValidate
						onSubmit={handleSubmit(({ email }) =>
							mutate({ email, formUrl: action }),
						)}
					>
						<Flex sx={styles.form}>
							<FormControl isInvalid={Boolean(errors.email)}>
								<VisuallyHidden>
									<FormLabel htmlFor="email">
										{t("newsletter.email.label")}
									</FormLabel>
								</VisuallyHidden>
								<Input
									{...register("email")}
									data-testid="email"
									placeholder={t(
										"newsletter.email.placeholder",
									)}
									variant="filled"
									sx={styles.input}
								/>
								<FormErrorMessageI18n error={errors.email} />
							</FormControl>
							<FormSubmitIconButton
								aria-label={t("newsletter.submit.label")}
								sx={styles.submit}
								isLoading={isLoading}
								isValid={!errors.email}
								icon={<Submit />}
								id="submit_newsletter_form"
							/>
						</Flex>
					</form>

					<chakra.p fontSize="xs">
						{t("newsletter.privacy", {
							link: (text: string) => (
								<Link
									href={t("newsletter.privacyPolicy.url")}
									isExternal
									id="click_newsletter_privacy"
								>
									{text}
								</Link>
							),
						})}
					</chakra.p>
				</chakra.div>
			</Container>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
