import { ProductSingleBlockFragment } from "src/__generated__/client.codegen";
import {
	StructuredTextData,
	useGroupedStructuredText,
} from "src/components/StructuredText/StructuredText";

export const useGetProductsFromStructuredText = (
	structuredText?: StructuredTextData,
) => {
	const structuredTexts = useGroupedStructuredText(structuredText, [
		"intro",
		"items",
	]);

	let [intro, items] = structuredTexts;

	// if items is undefined, we assume intro contains products
	if (!items) {
		items = intro;
		intro = undefined;
	}

	const products = items?.blocks?.filter(
		(b): b is ProductSingleBlockFragment =>
			b.__typename === "ProductRecord",
	);

	return { intro, products };
};

// 🔬 TBD
