import { useMutation } from "@tanstack/react-query";
import mapValues from "lodash/mapValues";
import { format } from "src/components/DatePicker/utils";
import { z } from "src/lib/zod";
import { TypeOf } from "zod";
import { postToPublicApi } from "./publicApi";

const sendEmailSchema = z.object({
	emailTemplateSlug: z.string(),
	emailSubject: z.string(),
	payload: z.record(
		z.string().min(1),
		z.union([z.string(), z.date(), z.number()]),
	),
});

/**
 * Apparently the EIS Emil Templating Engine does something funny with Date ISO Strings (returning [Object object]).
 * Let's format the value as user-friendly strings before passing it to the API.
 *
 * @param value The value to transform
 * @returns The transformed value (if it was a Date)
 */
const formatDate = (value: string | Date | number) =>
	value instanceof Date ? format(value) : value;

export const useSendEmail = () =>
	useMutation({
		mutationFn: async ({
			emailTemplateSlug,
			emailSubject,
			payload,
		}: TypeOf<typeof sendEmailSchema>) =>
			postToPublicApi(`emails/send`, {
				templateSlug: emailTemplateSlug,
				emailSubject,
				payload: mapValues(payload, formatDate),
			}),
	});

// 🔬 TBD: Please evaluate
