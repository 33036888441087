import { useGetAllBlogpostsForBlogSectionQuery } from "src/__generated__/client.codegen";
import { useSiteSettings } from "src/lib/hooks";
import { isString } from "src/lib/utils";

export const useGetBlogpostsFromCMS = (brandSlug?: string | Array<string>) => {
	const { locale } = useSiteSettings();

	const { data, isLoading } = useGetAllBlogpostsForBlogSectionQuery({
		locale,
	});

	if (!isString(brandSlug)) {
		return { isLoading, blogposts: [] };
	}

	const blogposts = data?.allBlogposts.filter((blogpost) =>
		blogpost.brands.map((brand) => brand.slug).includes(brandSlug),
	);

	if (!blogposts || blogposts.length === 0) {
		return { isLoading, blogposts: [] };
	}

	return {
		isLoading,
		blogposts,
	};
};

// 🔬 TBD: Please evaluate
