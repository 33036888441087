import { useMutation } from "@tanstack/react-query";
import ky from "ky";

export const useNewsletterSignup = (onSuccess: () => void) => {
	return useMutation({
		mutationFn: async ({
			email,
			formUrl,
		}: {
			email: string;
			formUrl: string;
		}) => {
			const formData = new FormData();

			formData.append("EMAIL", email);
			formData.append("OPT_IN", "1");

			const res = await ky.post(`${formUrl}?isAjax=1`, {
				body: formData,
			});

			const body = await res.json<{ success: boolean }>();

			if (body.success) {
				onSuccess();
			}
		},
	});
};

// 🔬 skip
