import {
	chakra,
	ChakraProps,
	Link,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { useSectionNavigatorContext } from "src/components/SectionNavigator/SectionNavigator";
import {
	StructuredText,
	StructuredTextData,
	useGroupedStructuredText,
} from "src/components/StructuredText/StructuredText";
import { useTranslatedString } from "src/i18n/i18n";
import { ArrowDown } from "src/icons";
import { getAnalyticsId } from "src/lib/utils.esm";

type TextSectionProps = ChakraProps & {
	structuredText: StructuredTextData;
	showArrow: boolean;
	variant: string;
	anchorId: string;
};

export const TextSection: React.FC<TextSectionProps> = ({
	showArrow,
	structuredText: structuredTextRaw,
	variant,
	anchorId,
	...props
}) => {
	const styles = useMultiStyleConfig("TextSection", { variant });
	const t = useTranslatedString();

	const [structuredText, ...buttons] = useGroupedStructuredText(
		structuredTextRaw,
		["intro", "buttons"],
	);

	const { allSections } = useSectionNavigatorContext();

	const thisAnchorIndex = allSections.findIndex(
		(s) => s.anchorId === anchorId,
	);
	const nextAnchorId =
		thisAnchorIndex >= 0
			? allSections[thisAnchorIndex + 1]?.anchorId ?? ""
			: "";

	return (
		<chakra.section __css={styles.wrapper} {...props}>
			<chakra.div __css={styles.container}>
				{structuredText && <StructuredText data={structuredText} />}

				{buttons.length > 0 && (
					<chakra.div __css={styles.buttons}>
						{buttons.map((button) => (
							<StructuredText key={button.key} data={button} />
						))}
					</chakra.div>
				)}

				{showArrow && (
					<Link
						href={`#${nextAnchorId}`}
						sx={styles.arrow}
						title={t("jumpToNext")}
						id={getAnalyticsId(`click_jump`, nextAnchorId)}
					>
						<ArrowDown />
					</Link>
				)}
			</chakra.div>
		</chakra.section>
	);
};

// 🔬 Covered by story
