import {
	chakra,
	Container,
	GridItem,
	SimpleGrid,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { DatoAsset, DatoAssetData } from "src/components/Dato/DatoAsset";
import {
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";

export type ImageTextSectionProps = {
	imageData: DatoAssetData;
	structuredText?: StructuredTextData;
};

const desktopBreakpoint = "md";

export const ImageTextSection: React.FC<ImageTextSectionProps> = ({
	imageData,
	structuredText,
}) => {
	const styles = useMultiStyleConfig("ImageTextSection", {
		desktopBreakpoint,
	});

	return (
		<Container sx={styles.wrapper}>
			<SimpleGrid
				sx={styles.grid}
				columns={{ base: 1, [desktopBreakpoint]: 2 }}
			>
				<GridItem sx={styles.textOuter}>
					<chakra.div __css={styles.text}>
						{structuredText && (
							<StructuredText data={structuredText} />
						)}
					</chakra.div>
				</GridItem>
				<GridItem sx={styles.imageWrapper}>
					<DatoAsset
						sx={styles.image}
						datoImageProps={{
							objectFit: "cover",
							layout: "fill",
						}}
						usePlaceholder={true}
						data={imageData}
					/>
				</GridItem>
			</SimpleGrid>
		</Container>
	);
};

// 🔬 TBD: Please evaluate
