import {
	chakra,
	Container,
	Grid,
	GridItem,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { BackgroundShape } from "src/components/BackgroundShape/BackgroundShape";
import { DatoAsset, DatoAssetData } from "src/components/Dato/DatoAsset";

type MissionProps = {
	imageData?: DatoAssetData;
	children?: React.ReactNode;
	variant?: "whiteBackground";
};

export const Mission: React.FC<MissionProps> = ({
	imageData,
	children,
	variant,
}) => {
	const styles = useMultiStyleConfig("Mission", {
		variant,
	});

	return (
		<chakra.div __css={styles.wrapper}>
			<BackgroundShape
				offset={75}
				variant={
					variant === "whiteBackground"
						? "whiteBackground"
						: undefined
				}
			/>

			<Container maxW="container.xl" sx={styles.inner}>
				<Grid gap="grid.gap" templateColumns="repeat(12, 1fr)">
					<GridItem
						colSpan={{ base: 12, md: 6, xl: 5 }}
						sx={styles.left}
					>
						{children}
					</GridItem>
					<GridItem colSpan={{ base: 12, md: 6, xl: 7 }}>
						{imageData && (
							<chakra.div __css={styles.image}>
								<DatoAsset
									usePlaceholder={false}
									data={imageData}
								/>
							</chakra.div>
						)}
					</GridItem>
				</Grid>
			</Container>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
