import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { CtaSectionFragment } from "src/__generated__/cms-schema.codegen";
import { BackgroundShape } from "src/components/BackgroundShape/BackgroundShape";
import { CMSButton } from "src/components/CMSButton/CMSButton";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import { Hx } from "src/components/Heading/Heading";

type CallToActionSectionProps = Omit<CtaSectionFragment, "__typename">;

export const CallToActionSection: React.FC<CallToActionSectionProps> = ({
	title,
	description,
	linkButtons,
}) => {
	const styles = useMultiStyleConfig("CallToActionSection");

	return (
		<chakra.div __css={styles.wrapper}>
			<BackgroundShape offset={70} />
			<Hx size="h2" sx={styles.title}>
				{title}
			</Hx>
			{description && (
				<DatoRichTextContent
					__css={styles.description}
					html={description}
				/>
			)}
			{linkButtons.map((cmsButton) => (
				<chakra.div key={cmsButton.id} sx={styles.buttonWrapper}>
					<CMSButton {...cmsButton} />
				</chakra.div>
			))}
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
