import { chakra, ChakraProps } from "@chakra-ui/react";
import { parseISO } from "date-fns";
import React from "react";
import { format } from "../DatePicker/utils";

type DateDisplayProps = ChakraProps & {
	date: string;
};

export const DateDisplay: React.FC<DateDisplayProps> = ({ date, ...props }) => {
	return (
		<chakra.time title={date} dateTime={date} {...props}>
			{format(parseISO(date))}
		</chakra.time>
	);
};

// 🔬 story (visual regression testing TBD)
