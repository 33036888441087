import { Box, chakra, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { Hx } from "src/components/Heading/Heading";
import { DatoAsset, DatoAssetData } from "../Dato/DatoAsset";
import { DatoRichTextContent } from "../Dato/DatoRichTextContent";

const Benefit: React.FC<{
	title: string;
	text: string;
	variant?: string;
	icon: DatoAssetData;
}> = ({ title, text, icon, variant }) => {
	const styles = useMultiStyleConfig("Benefit", { variant });

	return (
		<Box __css={styles.wrapper}>
			<chakra.div __css={styles.iconWrapper}>
				<DatoAsset
					as="div"
					sx={styles.icon}
					w={24}
					h={24}
					data={icon}
				/>
			</chakra.div>
			<chakra.div>
				<Hx sx={styles.title}>{title}</Hx>
				<DatoRichTextContent __css={styles.text} html={text} />
			</chakra.div>
		</Box>
	);
};

export { Benefit };

// 🔬 TBD: Please evaluate
