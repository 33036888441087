import {
	Button,
	chakra,
	ChakraProps,
	HStack,
	useBreakpointValue,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { ChevronUp } from "src/icons";
import { Pagination, Autoplay, A11y, Navigation } from "swiper";
import {
	Swiper,
	SwiperSlide,
	SwiperProps,
	SwiperSlideProps,
	useSwiper,
} from "swiper/react";

const buttonStyle = {
	position: "static",
	marginTop: 0,
	width: "3rem",
	height: "3rem",
	background: "var(--chakra-colors-brand-lighter)",
	borderRadius: "6px",
	border: "none",
	color: "var(--chakra-colors-brand-dark)",
	boxShadow: "none",
	flexShrink: 0,
} as const;

type CarouselControlProps = ChakraProps & { paginationClassName?: string };

export const CarouselControls: React.FC<CarouselControlProps> = ({
	paginationClassName,
	...props
}) => {
	const styles = useMultiStyleConfig("Carousel");

	return (
		<HStack sx={styles.control} {...props}>
			<Button
				style={{ ...buttonStyle, marginRight: "0.5rem" }}
				className="swiper-button-prev"
				id="prev"
			>
				<ChevronUp transform="rotate(270deg)" />
			</Button>
			<chakra.div
				__css={styles.pagination}
				className={paginationClassName}
			/>
			<Button
				style={{ ...buttonStyle, marginLeft: "0.5rem" }}
				className="swiper-button-next"
				id="next"
			>
				<ChevronUp transform="rotate(90deg)" />
			</Button>
		</HStack>
	);
};

// The design requests that on mobile there is no autoplay. We can use chakra's useBreakpointValue to detect changes in breakpoints,
// but Swiper doesn't respond to prop changes after initial load. so instead of dynamically updating the autoplay prop,
// we have to use the swiper instance via useSwiper, which works when the hook is used in a child of <Swiper>.
const ResponsiveSwiperAutoplay: React.FC = () => {
	const swiper = useSwiper();

	const [prevAutoSetting, setAutoSetting] = useState(true);
	const enableAutoplay = useBreakpointValue(
		{
			base: false,
			md: true,
		},
		{
			fallback: "md",
		},
	);

	useEffect(() => {
		// It can apparently be undefined, at least in testing.
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (swiper.autoplay && prevAutoSetting !== enableAutoplay) {
			enableAutoplay ? swiper.autoplay.start() : swiper.autoplay.stop();
			setAutoSetting(Boolean(enableAutoplay));
		}
	}, [enableAutoplay, prevAutoSetting, swiper.autoplay]);

	return null;
};

export type CarouselProps = SwiperProps & {
	control?: ReactElement<CarouselControlProps>;
};

export const Carousel: React.FC<CarouselProps> = ({
	id,
	children,
	control,
	...props
}) => {
	const styles = useMultiStyleConfig("Carousel");

	// on pages with two or more swipers, we need the className to be unique for the pagination to work properly
	const paginationClassName = `swiper-pagination-${id}`;

	// clone the control to add the id
	const controlWithClassName = React.isValidElement(control)
		? React.cloneElement(control, { paginationClassName })
		: null;

	return (
		<chakra.div sx={styles.wrapper}>
			<Swiper
				spaceBetween={24}
				slidesPerView="auto"
				centeredSlides={true}
				grabCursor={true}
				autoplay={{
					delay: 8000,
					disableOnInteraction: false,
				}}
				modules={[Pagination, Navigation, Autoplay, A11y]}
				pagination={{
					clickable: true,
					el: `.${paginationClassName}`,
					renderBullet: (index, className) =>
						`<div class="${className}"><span>${index + 1}</span></div>`,
				}}
				navigation={{
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				}}
				{...props}
			>
				<ResponsiveSwiperAutoplay />
				{children}
				{controlWithClassName}
			</Swiper>
		</chakra.div>
	);
};

export const CarouselItem: React.FC<SwiperSlideProps> = ({
	children,
	...rest
}) => {
	return <SwiperSlide {...rest}>{children}</SwiperSlide>;
};

// Without this, Swiper will not work.
CarouselItem.displayName = "SwiperSlide";

// 🔬 TBD: Please evaluate
